import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import ticketI from "../../images/ticketsI.png";
import visaI from "../../images/visaI.png";
import hotelI from "../../images/hotelI.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import formatDate from "../functions/timeformat";
import { CommonINTAirlines } from "../common/CommonINTAirlines";

function UpdateTicket(props) {
  let history = useHistory();

  var id = props.match.params.id;
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [ttype, setTtype] = useState("");
  const [serial, setSerial] = useState("");
  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");

  const [adult, setAdult] = useState("");
  const [infant, setInfant] = useState("");
  const [baby, setBaby] = useState("");

  const [doc4, setDoc4] = useState("");
  const [doc5, setDoc5] = useState("");

  const [show, setShow] = useState(false);

  const [gpnr, setGpnr] = useState("");
  const [edate, setEdate] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [cof, setCof] = useState("");
  const [note, setNote] = useState("");
  const [tnote, setTnote] = useState("");

  const [tranlocation, setTranLocation] = useState("");
  const [trantime, setTrantime] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev",
        {
          id: id,
        }
      )
      .then((res) => {
        console.log();
        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setIdate(res.data.message[0].idate);
        setAirplane(res.data.message[0].airplane);
        setFlight(res.data.message[0].flight);
        setVendor(res.data.message[0].vendor);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setType(res.data.message[0].type);
        setTnote(res.data.message[0].tnote);

        setAdult(res.data.message[0].adult);
        setInfant(res.data.message[0].infant);
        setBaby(res.data.message[0].baby);

        setGpnr(res.data.message[0].gpnr);

        setEdate(res.data.message[0].edate);
        setCof(res.data.message[0].cof);

        setTranLocation(res.data.message[0].tranlocation);
        setTrantime(res.data.message[0].trantime);

        setNote(res.data.message[0].note);

        if (res.data.message[0].aid == 0) {
          setShow(true);
        }

        document.getElementById("inputair").value =
          res.data.message[0].airplane;
        document.getElementById("inputven").value = res.data.message[0].vendor;

        setArr1(
          res.data.message[0].arrival.substr(
            0,
            res.data.message[0].arrival.indexOf(" ")
          )
        );
        setDe1(
          res.data.message[0].departure.substr(
            0,
            res.data.message[0].departure.indexOf(" ")
          )
        );

        var fdate = res.data.message[0].arrival;
        fdate = fdate
          .replace(fdate.substr(0, fdate.indexOf(" ")), "")
          .replace(" ", "");
        var k = fdate.substr(0, 11).replace(" ", "");

        console.log("hey" + k);
        setArr2(k.replace(" ", ""));

        var time1 = fdate.replace(fdate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setT1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setT2(time2);

        var ddate = res.data.message[0].departure;
        ddate = ddate
          .replace(ddate.substr(0, ddate.indexOf(" ")), "")
          .replace(" ", "");
        var ko = ddate.substr(0, 11).replace(" ", "");
        setDe2(ko.replace(" ", ""));

        var time1 = ddate.replace(ddate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setD1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setD2(time2);
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleSubmit(e) {
    e.preventDefault();

    var arrival = arr1 + "  " + arr2 + " ";
    var departure = de1 + " " + de2 + " ";

    const data = new FormData();

    data.append("passport", passport);
    data.append("nid", nid);
    data.append("flight", flight);
    data.append("base", base);
    data.append("gross", gross);
    data.append("idate", idate);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("arrival", arrival);
    data.append("departure", departure);
    data.append("airplane", airplane);
    data.append("vendor", vendor);
    data.append("serial", serial);
    data.append("gpnr", gpnr);
    data.append("edate", edate);
    data.append("cof", cof);
    data.append("tnote", tnote);
    data.append("adult", adult);
    data.append("infant", infant);
    data.append("baby", baby);
    data.append("trantime", trantime);
    data.append("tranlocation", tranlocation);
    data.append("token", localStorage.getItem("token"));
    data.append("id", id);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upticket",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("Ticket updated", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          document.getElementById("create-course-form").reset();
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => console.log(err));

    history.goBack();
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>
          <hr />

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1"></div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Issue Date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={idate}
                    onChange={(e) => setIdate(e.target.value)}
                  />
                </div>
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Airplane
                </label>
                <div class="col-lg-4 col-12">
                  <CommonINTAirlines setValue={setAirplane} value={airplane} />
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Departure
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DUBAI"
                    class="form-control"
                    required=""
                    value={de1}
                    onChange={(e) => setDe1(e.target.value)}
                  />
                </div>
                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder="Ex. 14:45 24 April"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      required=""
                      value={de2}
                      onChange={(e) => {
                        setDe2(e.target.value);
                        document.getElementById("clbtn2").click();
                      }}
                    />
                  </div>
                </div>

                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Arrival
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DAC"
                    class="form-control"
                    required=""
                    value={arr1}
                    onChange={(e) => setArr1(e.target.value)}
                  />
                </div>
                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder="Ex. 14:45 24 April"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      required=""
                      value={arr2}
                      onChange={(e) => {
                        setArr2(e.target.value);
                        document.getElementById("clbtn").click();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Transit Route
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Location"
                    class="form-control"
                    required=""
                    value={tranlocation}
                    onChange={(e) => setTranLocation(e.target.value)}
                  />
                </div>

                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="1:00 Hour"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                        height: 26,
                      }}
                      //  class="form-control"
                      required=""
                      value={trantime}
                      onChange={(e) => {
                        setTrantime(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Flight No
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={flight}
                    onChange={(e) => setFlight(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Ticket Serial
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={serial}
                    onChange={(e) => setSerial(e.target.value)}
                  />
                </div>
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  PNR
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="XXXXXXXX"
                    class="form-control"
                    required=""
                    value={gpnr}
                    onChange={(e) => setGpnr(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                {/* <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Care Of
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={cof}
                    onChange={(e) => setCof(e.target.value)}
                  />
                </div> */}
              </div>

              {/* <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Transit Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    rows="2"
                    cols="40"
                    type="text"
                    class="form-control"
                    required=""
                    value={tnote}
                    onChange={(e) => setTnote(e.target.value)}
                  ></textarea>
                </div>
              </div> */}

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Additional Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    rows="2"
                    cols="40"
                    type="text"
                    class="form-control"
                    required=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Vendor Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      id="inputven"
                      class="form-select form-control"
                      value={vendor}
                      // onChange={(e) => setVendor(e.target.value)}
                      readOnly
                    >
                      <option class="form-control">Choose Vendor</option>
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for=""
                  class="col-lg-2 col-4 marginTop infotext col-form-label"
                >
                  Buying Price
                </label>
                <div class="col-lg-4 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={base}
                    onChange={(e) => setBase(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  {show ? "Selling Price" : "Agent Fare"}
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={gross}
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  VAT & AIT
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    placeholder="0.03"
                    required=""
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gross) - (Number(base) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  Profit
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={profit}
                    readOnly
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-1">
              <div class="col">
                <div class="d-grid gap-2 text-right">
                  <button class="btn btn-primary w-100">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(UpdateTicket);
