import Logo from "../../images/logo.png";
import Paid from "../../images/paids.svg";
function Printhead() {
  return (
    <>
      {/* <div class="onprint">
        <div class="d-flex justify-content-between ">
          <img src={Logo} class="inimg" alt="..." />
          <div class="mt-3" style={{ fontSize: 14 }}>
            <p class="m-0">
              <i class="fa fa-globe" aria-hidden="true"></i>{" "}
              {process.env.REACT_APP_ADDRESS}
              <br></br> {process.env.REACT_APP_ADDRESS2}
            </p>
            <p class="m-0">
              <i class="fa fa-phone" aria-hidden="true"></i>{" "}
              <span> {process.env.REACT_APP_PHONE}</span>
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Printhead;
