import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Printhead from "../common/printhead";

function Duelist() {
  const [cout, setCout] = useState([]);
  const [search, setSearch] = useState("");

  const [ticket, setTicket] = useState([]);
  const [visa, setVisa] = useState([]);
  const [passport, setPassport] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [man, setMan] = useState([]);
  const [agent, setAgent] = useState([]);

  const [allven, setAllven] = useState([]);

  const [purchasehis, setPurchasehis] = useState([]);

  const [dshow, setDshow] = useState("Customer");

  const [umrah, setUmrah] = useState([]);
  const [hajj, setHajj] = useState([]);
  const [medical, setMedical] = useState([]);

  const [report, setReport] = useState([]);
  const [treport, setTreport] = useState([]);

  const [role, setRole] = useState(false);
  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchticket"
      )
      .then((res) => {
        setTicket(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvisa"
      )
      .then((res) => {
        setVisa(res.data.message);
        console.log(res);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchpassport"
      )
      .then((res) => {
        setPassport(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchhotel"
      )
      .then((res) => {
        setHotel(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchman"
      )
      .then((res) => {
        setMan(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => {
        setAllven(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/inpurchase"
      )
      .then((res) => {
        setPurchasehis(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => {
        setAgent(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/umrahfetch"
      )
      .then((res) => {
        setUmrah(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/hajjfetch"
      )
      .then((res) => {
        setHajj(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/medicalfetch"
      )
      .then((res) => {
        setMedical(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vreportfetchall"
      )
      .then((res) => {
        setReport(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/treportfetchall"
      )
      .then((res) => {
        setTreport(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function getBalance(x) {
    var output;
    report.map((item, index) => {
      if (item.vid == x) {
        output = item.balance;
      }
    });

    return output;
  }

  function getAgentBalance(x) {
    var filteredItems = treport.filter((item) => item.vid == x);

    var balance = filteredItems.reduce((acc, item) => {
      return acc + (item.payable - item.paid);
    }, 0);

    return balance;
  }

  function total() {
    var i = 0;
    cout
      .filter((item) => {
        if (
          item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return item;
        }

        if (
          item.date.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        ) {
          return item;
        }
      })
      .map((item) => {
        i = i + item.amount;
      });

    return i;
  }

  function printme() {
    window.print();
  }

  function getit(x) {
    var i = 0;
    purchasehis.map((item) => {
      if (item.vid == x) {
        i = Number(i) + Number(item.price);
      }
    });

    return i;
  }

  function getit2(x) {
    var i = 0;
    ticket.map((item) => {
      if (item.aid == x) {
        i = Number(i) + Number(item.gross);
      }
    });

    visa.map((item) => {
      if (item.aid == x) {
        i = Number(i) + Number(item.gross);
      }
    });

    return i;
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  // useEffect(() => {
  //   var d = new Date();

  //   var m = d.getMonth() + 1;
  //   var day = d.getDate();
  //   if (day < 10) {
  //     day = "0" + day;
  //   }

  //   if (m < 10) {
  //     m = "0" + m;
  //   }

  //   var x = d.getFullYear() + "-" + m;
  //   axios
  //     .post(
  //       "https://concorde-api.flydocx.com/" + process.env.REACT_APP_URL + "/areportfetchAll",
  //       // {
  //       //   uid: id,
  //       // }
  //     )
  //     .then((res) => {
  //       console.log(res)
  //       setAgentData(res.data.message);
  //       console.log(res.data.message);
  //     })
  //     .catch((err) => console.log(err));

  // }, []);

  return (
    <>
      <Navigation2 />
      {/* <Printhead /> */}
      {/* <p>{JSON.stringify(agent)}</p> */}

      {/* <p>{JSON.stringify(treport)}</p> */}
      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <td class="tranreportheader"></td>
          </tr>
          <tr>
            <td style={{ height: 20 }}></td>
          </tr>
          <br />
        </thead>
        <tbody>
          <td>
            <div class="container">
              <div class="dtran">
                <div class="text-center">
                  <h4 class="m-0">Customer Due List</h4>
                </div>

                <div class="row align-items-center justify-content-between noprint bg-light border py-2 mx-auto">
                  <div class="col-lg-2 col-12">
                    <h5 class="m-0">Due list</h5>
                  </div>
                  <div class="col-lg-2 col-12">
                    <select
                      class="form-control form-select"
                      onChange={(e) => setDshow(e.target.value)}
                    >
                      <option selected>Select one</option>
                      <option>Customer</option>
                      <option>Vendor</option>
                      <option>Agent</option>
                    </select>
                  </div>

                  <div class="col-lg-2 col-3 text-end">
                    <button
                      class="btn btn-warning noprint"
                      onClick={printme}
                      style={{ color: "white", height: 35, paddingTop: 4 }}
                    >
                      Download
                    </button>
                  </div>
                </div>

                <div class="prbm">
                  <div class="container">
                    <div class="row align-items-center">
                      <table
                        class="table stranwidth table-bordered"
                        id="table-to-xls3"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th width="5%" scope="col" class="p-1 px-2">
                              SL
                            </th>
                            <th width="40%" scope="col" class="p-1 px-2">
                              Particulars
                            </th>
                            <th width="25%" scope="col" class="p-1 px-2">
                              Type
                            </th>
                            <th
                              width="15%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Payable
                            </th>
                            <th
                              width="15%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Due
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dshow == "Customer" ? (
                            <>
                              {ticket
                                .filter((item) => {
                                  if (item.done == 0 && item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          {item.type == "bank"
                                            ? item.type + "(" + item.bank + ")"
                                            : item.type}{" "}
                                          Ticket
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {visa
                                .filter((item) => {
                                  if (item.status == "" && item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Visa
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {passport
                                .filter((item) => {
                                  if (item.status == "" && item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Passport
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {hotel
                                .filter((item) => {
                                  if (item.status == "" && item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Hotel
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {man
                                .filter((item) => {
                                  if (item.status == "" && item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Manpower
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {umrah
                                .filter((item) => {
                                  if (item.status == "" && item.aid == 0) {
                                    return item;
                                  }
                                })

                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Umrah
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}

                              {hajj
                                .filter((item) => {
                                  if (item.aid == 0) {
                                    return item;
                                  }
                                })
                                .map((item) => (
                                  <>
                                    {item.gross - item.paid > 0 ? (
                                      <tr>
                                        <td
                                          width="5%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.id}
                                        </td>
                                        <td
                                          width="40%"
                                          scope="row"
                                          class="p-1 px-2"
                                        >
                                          {item.cname}
                                        </td>
                                        <td width="25%" class="p-1 px-2">
                                          Hajj
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross}
                                        </td>
                                        <td
                                          width="15%"
                                          class="text-right p-1 px-2"
                                        >
                                          {item.gross - item.paid}
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                ))}
                            </>
                          ) : null}

                          {dshow == "Vendor" ? (
                            <>
                              {allven.map((item, index) => (
                                <>
                                  <tr>
                                    <td width="5%" scope="row" class="p-1 px-2">
                                      {/* {item.id} */}
                                      {index + 1}
                                    </td>
                                    <td
                                      width="40%"
                                      scope="row"
                                      class="p-1 px-2"
                                    >
                                      {item.vname} (Vendor)
                                    </td>
                                    <td width="25%" class="p-1 px-2">
                                      {item.vtype}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {/* {getBalance(item.id)} */}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {getBalance(item.id)}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : null}

                          {dshow == "Agent" ? (
                            <>
                              {agent.map((item, index) => (
                                <>
                                  <tr>
                                    <td width="5%" scope="row" class="p-1 px-2">
                                      {/* {item.id} */}
                                      {index + 1}
                                    </td>
                                    <td
                                      width="40%"
                                      scope="row"
                                      class="p-1 px-2"
                                    >
                                      {item.aname} (Agent)
                                    </td>
                                    <td width="25%" class="p-1 px-2">
                                      {item.type}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {/* {getAgentBalance(item.id)} */}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {getAgentBalance(item.id)}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br /> <br />
                  <br /> <br />
                </div>
              </div>
            </div>
          </td>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: "100px" }}></td>
          </tr>
        </tfoot>
      </table>
      <footer
        class="footer"
        style={{ position: "fixed", bottom: 0, bottom: 0, left: 0 }}
      >
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Accounts</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <div class="dflex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>
            Developed by mPair Technologies Ltd.
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: "bold",
              position: "absolute",
              right: 0,
            }}
          >
            Generated by {role}
          </p>
        </div>
      </footer>
    </>
  );
}

export default Duelist;
