import React from "react";

export const CommonINTAirlines = ({ value, setValue }) => {
  const airlines = [
    "Air Arabia",
    "Air Asia",
    "Air India",
    "Air KZB Company Ltd.",
    "Biman Bangladesh Airlines",
    "Bangkok Airlines",
    "Bismillah Airlines",
    "British Airways",
    "Cathay Pacific",
    "China Eastern Airlines",
    "China Southern Airlines",
    "Dragonair",
    "Etihad Airways",
    "Finnair",
    "Fly Emirates",
    "Fly Dubai",
    "Gulf Air",
    "Himalaya Airlines",
    "Indigo",
    "Jazeera Airways",
    "Jet Airways",
    "Kuwait Airways",
    "Malaysia Airlines",
    "Malindo Air",
    "Novoair",
    "Oman Air",
    "Pakistan International Airlines",
    "Qatar Airways",
    "Regent Airways",
    "Salam Air",
    "Saudi Arabian Airlines",
    "Saudia",
    "Singapore Airlines",
    "SpiceJet",
    "Srilankan Airlines",
    "Thai Airways",
    "Thai Airways International",
    "Turkish Airlines",
    "United Airlines",
    "US-Bangla Airlines",
    "Yemenia",
    "Thai Lion",
    "China Eastern",
    "China Southern",
    "Ethiopian",
    "Blue Sky",
    "Air China",
    "Saudi Arabian Airlines",
  ].sort();
  return (
    <>
      <div class="input-group">
        <div class="input-group-prepend"></div>
        <select
          id="inputState"
          class="form-select form-control"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <option class="form-control" selected>
            Choose Airlines
          </option>
          {airlines.map((airline, index) => (
            <option key={index} value={airline}>
              {airline}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
